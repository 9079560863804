import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Petition } from '../../../petitions/models/petition.model';

@Component({
  selector: 'app-petition',
  templateUrl: './petition.component.html',
  styleUrls: ['./petition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetitionComponent implements OnInit {
  @Input() petition: Petition;
  @Input() editable = true;
  @Output() deleted = new EventEmitter<Petition>();
  @Output() edited = new EventEmitter<Petition>();

  constructor() {}

  ngOnInit() {}

  onDelete() {
    this.deleted.emit(this.petition);
  }

  onEdit() {
    this.edited.emit(this.petition);
  }
}
