import { Component, OnInit, OnDestroy } from '@angular/core';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { Petition } from '../models/petition.model';
import { AppState } from '../../reducers/index';
import { Store } from '@ngrx/store';
import * as fromPetitions from './../store/petitions.actions';
import { Observable, Subscription } from 'rxjs';
import { getPetitions, getAllLoaded } from '../store/petitions.selectors';
import { take } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../shared/components/confirm-modal/confirm-modal.component';
import { PetitionModalComponent } from '../../shared/components/petition-modal/petition-modal.component';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-petitions',
  templateUrl: './petitions.component.html',
  styleUrls: ['./petitions.component.scss'],
})
export class PetitionsComponent implements OnInit, OnDestroy {
  petitions$: Subscription;
  petitions: Petition[];
  isLoading$: Observable<boolean>;
  modalRef: MDBModalRef;

  modalConfig = {
    class: 'modal-dialog-centered',
  };

  constructor(
    private store: Store<AppState>,
    private modalService: MDBModalService,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.isLoading$ = this.store.select(getAllLoaded);
    this.petitions$ = this.store.select(getPetitions)
      .subscribe((petitions: Petition[]) => {
        if (this.user && petitions) {
          this.petitions = petitions;
        } else {
          this.store.dispatch(new fromPetitions.PetitionsQuery());
        }
      });
  }

  get user() {
    return this.afAuth.auth.currentUser;
  }

  ngOnDestroy() {
    this.petitions$.unsubscribe();
  }

  openAddPetitionModal() {
    this.modalRef = this.modalService.show(
      PetitionModalComponent,
      this.modalConfig
    );

    this.modalRef.content.heading = 'Add new petition';

    this.modalRef.content.petitionData
      .pipe(take(1))
      .subscribe((petitionData: Petition) => {
        this.store.dispatch(
          new fromPetitions.PetitionAdded({ petition: petitionData })
        );
      });
  }

  openEditPetitionModal(petition: Petition) {
    this.modalRef = this.modalService.show(
      PetitionModalComponent,
      this.modalConfig
    );

    this.modalRef.content.heading = 'Edit petition';
    const petitionCopy = { ...petition };
    this.modalRef.content.petition = petitionCopy;

    this.modalRef.content.petitionData
      .pipe(take(1))
      .subscribe((petitionData: Petition) => {
        this.store.dispatch(
          new fromPetitions.PetitionEdited({ petition: petitionData })
        );
      });
  }

  openConfirmModal(petition: Petition) {
    this.modalRef = this.modalService.show(
      ConfirmModalComponent,
      this.modalConfig
    );

    this.modalRef.content.confirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.store.dispatch(new fromPetitions.PetitionDeleted({ petition }));
        }
      });
  }

  onPetitionDelete(petition: Petition) {
    this.openConfirmModal(petition);
  }

  onPetitionEdit(petition: Petition) {
    this.openEditPetitionModal(petition);
  }
}
