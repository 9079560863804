import { Injectable } from '@angular/core';
import { Project } from '../models/project.model';
import { environment } from '../../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
// import * as firebase from 'firebase/app';
// import { Observable, from } from 'rxjs';
// import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  url = environment.firebase.databaseURL;
  projectUsers: any;

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth
  ) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(project: Project, userId: string) {
    project.uid = userId;
    const projects = this.db.list(`projects/${userId}`);
    var returnedProjectKey = projects.push(project);
    this.db.list('projectusers').push({ projectid: returnedProjectKey.key, uid: userId });
    return returnedProjectKey;
  }

  addProjectsNew(project: Project, userId: string) {
    project.uid = userId;
    const projects = this.db.list('projectsnew');
    var returnedProjectKey = projects.push(project);
    this.db.list('projectusers').push({ projectid: returnedProjectKey.key, uid: userId });
    return returnedProjectKey;
  }


  addProjects(projects: Project[]) {
    const userId = this.userId;
    projects.forEach((project: Project) => {
      this.db.list(`projects/${userId}`).push(project);
      this.db.list(`/projectusers/${project.key}`).push({ projectid: project.key, uid: userId });
    });
  }

  get(userId: string) {
    return this.db.list(`projects/${userId}`).snapshotChanges();
  }

  // The below function implements for getting all projects using the join functionlity
  // for admin privileges
  getProjectsNew() {
    return this.db.list('projectsnew').snapshotChanges();
  }

  // The below function implements for the new db 'projectsnew' for particular userId. Please refer the firebase db.
  getProjectsNewForUser(userId: string) {
    return this.db.list('projectsnew', ref => {
      return ref.orderByChild('uid').equalTo(userId)
    }).snapshotChanges();
  }

  getAllUserProjects() {
    const userprojects = this.db.list('projectusers').snapshotChanges();
    return userprojects;
  }

  getAll() {
    const projects = this.db.list('projects').snapshotChanges();
    return projects;
  }

  update(project: Project, userId: string) {
    return of(
      this.db.object(`projects/${userId}/` + project.key).update({
        title: project.title,
        description: project.description,
        photoUrl: project.photoUrl,
        startDate: project.startDate,
        endDate: project.endDate,
        uid: userId
      })
    );
  }

  updateProjectsNew(project: Project, userId: string) {
    return of(
      this.db.object(`projectsnew/${project.key}`).update({
        title: project.title,
        description: project.description,
        photoUrl: project.photoUrl,
        startDate: project.startDate,
        endDate: project.endDate,
        uid: userId
      })
    );
  }

  delete(project: Project, userId: string) {
    return this.db.object(`projects/${userId}/` + project.key).remove();
  }

  deleteProjectsNew(project: Project) {
    return this.db.object(`projects/${project.key}/`).remove();
  }
}
