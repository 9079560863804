import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PetitionsComponent } from './containers/petitions.component';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import {
  ButtonsModule,
  InputsModule,
  CardsModule,
  WavesModule,
  IconsModule,
  ModalModule,
} from 'ng-uikit-pro-standard';

import * as fromPetitions from './store/petitions.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PetitionsEffects } from './store/petitions.effects';
import { FormsModule } from '@angular/forms';
import { PetitionsRoutingModule } from './petitions-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    SharedModule,
    PetitionsRoutingModule,
    HttpClientModule,
    FormsModule,
    ButtonsModule,
    InputsModule,
    WavesModule,
    IconsModule,
    CardsModule,
    StoreModule.forFeature('petitions', fromPetitions.petitionsReducer),
    EffectsModule.forFeature([PetitionsEffects]),
  ],
  declarations: [PetitionsComponent],
  exports: [PetitionsComponent],
})
export class PetitionsModule {}
