import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PetitionsActionTypes } from './petitions.actions';
import { Store, select } from '@ngrx/store';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import { Petition } from '../models/petition.model';
import { of } from 'rxjs';
import { PetitionsService } from '../services/petitions.service';
import * as fromPetitions from './../store/petitions.actions';
import { AppState } from '../../reducers/index';
import { getUser } from '../../auth/store/auth.selectors';

@Injectable()
export class PetitionsEffects {
  constructor(
    private actions$: Actions,
    private petitionsService: PetitionsService,
    private store: Store<AppState>
  ) {}

  @Effect()
  query$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONS_QUERY),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(() => {
      return this.petitionsService.get().pipe(
        map((data: any) => {
          const petitionsData: Petition[] = data.map((res: any) => {
            const key = res.payload.key;
            const petition: Petition = res.payload.val();
            return {
              key: key || null,
              serialNumber: petition.serialNumber || null,
              returnedDate: petition.returnedDate || null,
              notarizedAt: petition.notarizedAt || null,
              inRecovery: petition.inRecovery || null,
              county: petition.county || null,
              checkedOutAt: petition.checkedOutAt || null,
              campaignId: petition.campaignId || null,
              volunteerId: petition.volunteerId || null,
            };
          });
          return new fromPetitions.PetitionsLoaded({
            petitions: petitionsData,
          });
        }),
        catchError(error => of(new fromPetitions.PetitionsError({ error })))
      );
    })
  );

  @Effect()
  queryPetitionsNewGetAll$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONSNEWALL_QUERY),
    switchMap(() => {
      return this.petitionsService.getAllPetitions().pipe(
        map((data: any) => {
          const petitionsData: Petition[] = data.map((res: any) => {
            const key = res.payload.key;
            const petition: Petition = res.payload.val();
            return {
              key: key || null,
              serialNumber: petition.serialNumber || null,
              returnedDate: petition.returnedDate || null,
              notarizedAt: petition.notarizedAt || null,
              inRecovery: petition.inRecovery || null,
              county: petition.county || null,
              checkedOutAt: petition.checkedOutAt || null,
              campaignId: petition.campaignId || null,
              volunteerId: petition.volunteerId || null,
              uid: petition.uid || null
            };
          });
          return new fromPetitions.PetitionsLoaded({
            petitions: petitionsData,
          });
        }),
        catchError(error => of(new fromPetitions.PetitionsError({ error })))
      );
    })
  );

  @Effect()
  queryPetitionsNewGetAllByUser$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONSNEWALLBYUSER_QUERY),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([user]: any) => {
      let userid: string = user || user.uid;
      return this.petitionsService.getPetitionsByUser(userid).pipe(
        map((data: any) => {
          const petitionsData: Petition[] = data.map((res: any) => {
            const key = res.payload.key;
            const petition: Petition = res.payload.val();
            return {
              key: key || null,
              serialNumber: petition.serialNumber || null,
              returnedDate: petition.returnedDate || null,
              notarizedAt: petition.notarizedAt || null,
              inRecovery: petition.inRecovery || null,
              county: petition.county || null,
              checkedOutAt: petition.checkedOutAt || null,
              campaignId: petition.campaignId || null,
              volunteerId: petition.volunteerId || null,
              uid: petition.uid || null
            };
          });
          return new fromPetitions.PetitionsLoaded({
            petitions: petitionsData,
          });
        }),
        catchError(error => of(new fromPetitions.PetitionsError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  added$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITION_ADDED),
    map((action: fromPetitions.PetitionDeleted) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload]: any) => this.petitionsService.add(payload.petition))
  );

  @Effect({ dispatch: false })
  addedPetitionsNew$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONSNEW_ADDED),
    map((action: fromPetitions.PetitionDeleted) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload]: any) => this.petitionsService.addPetitionNew(payload.petition))
  );

  @Effect({ dispatch: false })
  delete$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITION_DELETED),
    map((action: fromPetitions.PetitionDeleted) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload]: any) =>
      this.petitionsService.delete(payload.petition)
    )
  );

  @Effect({ dispatch: false })
  deletePetitionNew$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONNEW_DELETED),
    map((action: fromPetitions.PetitionDeleted) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload]: any) =>
      this.petitionsService.deletePetitionsNew(payload.petition)
    )
  );

  @Effect({ dispatch: false })
  edit$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITION_EDITED),
    map((action: fromPetitions.PetitionEdited) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload]: any) =>
      this.petitionsService.update(payload.petition)
    )
  );

  @Effect({ dispatch: false })
  editPetitionsNew$ = this.actions$.pipe(
    ofType(PetitionsActionTypes.PETITIONSNEW_EDITED),
    map((action: fromPetitions.PetitionEdited) => action.payload),
    withLatestFrom(this.store.pipe(select(getUser))),
    switchMap(([payload, user]: any) =>
      this.petitionsService.updatePetitionsNew(payload.petition, user.uid)
    )
  );
}
