import { PetitionsActions, PetitionsActionTypes } from './petitions.actions';
import { petitionsInitialState, PetitionsState } from './petitions.state';

export function petitionsReducer(
  state = petitionsInitialState,
  action: PetitionsActions
): PetitionsState {
  switch (action.type) {
    case PetitionsActionTypes.PETITIONS_QUERY: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case PetitionsActionTypes.PETITIONS_LOADED: {
      return Object.assign({}, state, {
        petitions: action.payload.petitions,
        loading: false,
      });
    }

    case PetitionsActionTypes.PETITIONS_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        error: action.payload.error,
      });
    }

    default:
      return state;
  }
}
