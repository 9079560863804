import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmresetmodal',
  templateUrl: './confirmresetmodal.component.html',
  styleUrls: ['./confirmresetmodal.component.scss']
})
export class ConfirmresetmodalComponent implements OnInit {

  resetconfirmation: Subject<boolean> = new Subject();

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  onDelete() {
    this.resetconfirmation.next(true);
    this.modalRef.hide();
  }

}
