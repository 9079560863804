import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { BarChartDataset } from 'src/app/shared/models/bar-chart-dataset';
import { Petition } from 'src/app/petitions/models/petition.model';

import { AppState } from 'src/app/reducers';
import { getPetitions } from 'src/app/petitions/store/petitions.selectors';
import * as fromPetitions from 'src/app/petitions/store/petitions.actions';

@Component({
  selector: 'app-petitions-chart',
  templateUrl: './petitions-chart.component.html',
  styleUrls: ['./petitions-chart.component.scss']
})
export class PetitionsChartComponent implements OnInit, OnDestroy {
  private petitions$: Subscription;
  private petitions: Petition[];

  public datasets: BarChartDataset[];
  public counties: string[];
  public chartOptions = {
    responsive: true,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }]
    }
  };

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.petitions$ = this.store.select(getPetitions).subscribe((petitions: Petition[]) => {
      if (petitions) {
        this.petitions = petitions;
        this.setPetitionsData();
      } else {
        this.store.dispatch(new fromPetitions.PetitionsQuery());
      }
    });
  }

  ngOnDestroy() {
    this.petitions$.unsubscribe();
  }

  private setPetitionsData(): void {
    this.setCounties();
    this.setDataset();
  }

  private findCounts(fieldToCheck: 'notarizedAt' | 'checkedOutAt' | 'returnedDate'): number[] {
    return this.counties.map(county => this.petitions
      .filter((petition: Petition) => petition.county === county && !!petition[fieldToCheck])
      .length
    );
  }

  private setCounties(): void {
    this.counties = this.petitions
      .filter(petition => !!petition.county)
      .map(petition => petition.county)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  private setDataset(): void {
    this.datasets = [
      { data: this.findCounts('notarizedAt'), label: 'Notarized' },
      { data: this.findCounts('checkedOutAt'), label: 'Checked Out' },
      { data: this.findCounts('returnedDate'), label: 'Returned' }
    ];
  }
}
