import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Petition } from '../../../petitions/models/petition.model';

@Component({
  selector: 'app-petitions-list',
  templateUrl: './petitions-list.component.html',
  styleUrls: ['./petitions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetitionsListComponent implements OnInit {
  @Input() petitions: Petition[];
  @Input() editable = true;
  @Output() petitionDeleted = new EventEmitter<Petition>();
  @Output() petitionEdited = new EventEmitter<Petition>();

  constructor() {}

  ngOnInit() {}

  onPetitionDelete(petition: Petition) {
    this.petitionDeleted.emit(petition);
  }

  onPetitionEdit(petition: Petition) {
    this.petitionEdited.emit(petition);
  }

  trackByFunction(index: any) {
    return index;
  }
}
