import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { Petition } from '../../../petitions/models/petition.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-petition-modal',
  templateUrl: './petition-modal.component.html',
  styleUrls: ['./petition-modal.component.scss'],
})
export class PetitionModalComponent implements OnInit {
  @ViewChild('petitionForm', { static: true }) petitionForm: NgForm;

  heading: string;

  returnedDate: string;
  notarizedAt: string;
  inRecovery: boolean;
  county: string;
  checkedOutAt: string;
  campaignId: string;
  volunteerId: number;

  petitionData: Subject<Petition> = new Subject();
  petition: Petition = {
    key: '',
    serialNumber: '',
    county: '',
  };

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit() {}

  onSave() {
    if (this.petitionForm.valid) {
      this.petitionData.next(this.petition);
      this.modalRef.hide();
    } else {
      const controls = this.petitionForm.controls;
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
    }
  }
}
