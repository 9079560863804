import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, empty } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { ProjectsService } from '../../projects/services/projects.service';
import { PetitionsService } from '../../petitions/services/petitions.service';
import { CustomersService } from '../../customers/services/customers.service';
import { getUser } from '../../auth/store/auth.selectors';
import { switchMap, take } from 'rxjs/operators';
import { Customer } from '../../customers/models/customer.model';
import { Petition } from 'src/app/petitions/models/petition.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  projectsSub: Subscription;
  projects = [
    {
      title: 'Project 1',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg',
    },
    {
      title: 'Project 2',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(98).jpg',
    },
    {
      title: 'Project 3',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(99).jpg',
    },
    {
      title: 'Project 4',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
      photoUrl:
        'https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(95).jpg',
    },
  ];

  customersSub: Subscription;
  customers: Customer[] = [
    {
      id: 1,
      name: 'David',
      last_name: 'Lyft',
      email: 'dlyft@myemail.com',
      phone: '444-444-4444',
      zip_code: '85281',
      district: 6,
      message: 'This is a simple message',
      description: 'David is here to show a typical Volunteer with all data',
    },
    {
      id: 2,
      name: 'Erica',
      last_name: 'Russel',
      email: 'erica.Russel@gmail.com',
      phone: '253-715-6360',
      zip_code: '55069-1892',
      district: 27,
      message:
        'This is a bit more involved message than before as we test the limits.',
      description: 'Erica has full extention for her Zip',
    },
    {
      id: 3,
      name: 'Mrs. Andreane',
      last_name: 'Hermann',
      email: 'Andreane93@yahoo.com',
      phone: '801-860-4750',
      zip_code: '52782',
      district: 19,
      message: 'Tesing',
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      id: 4,
      name: 'Gerda',
      last_name: 'Wuckert',
      email: 'Gerda.Wuckert66@yahoo.com',
      phone: '1-409-756-5345 x5126',
      zip_code: '06435-7111',
      district: 14,
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      id: 5,
      name: 'Verlie',
      last_name: 'Beahan',
      email: 'Verlie_Beahan75@yahoo.com',
      phone: '734-702-2317 x58001',
      zip_code: '52382',
      district: 12,
      description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    },
  ];

  petitionsSub: Subscription;
  petitions: Petition[] = [
    {
      key: 1,
      serialNumber: 'A00001',
      returnedDate: '04/11/2019',
      notarizedAt: '04/11/2019',
      inRecovery: false,
      county: 'Maricopa',
      checkedOutAt: '03/27/2019',
    },
  ];

  constructor(
    private store: Store<AppState>,
    private projectsService: ProjectsService,
    private customersService: CustomersService,
    private petitionsService: PetitionsService
  ) {}

  ngOnInit() {
    this.initProjects();
    this.initCustomers();
    this.initPetitions();
  }

  ngOnDestroy() {
    if (this.projectsSub) {
      this.projectsSub.unsubscribe();
    }

    if (this.customersSub) {
      this.customersSub.unsubscribe();
    }

    if (this.petitionsSub) {
      this.petitionsSub.unsubscribe();
    }
  }

  initProjects() {
    this.projectsSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.projectsService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(projects => {
        if (projects.length === 0) {
          this.projectsService.addProjects(this.projects);
        }
      });
  }

  initCustomers() {
    this.customersSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.customersService.get(user.uid);
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(customers => {
        if (customers.length === 0) {
          this.customersService.addCustomers(this.customers);
        }
      });
  }

  initPetitions() {
    this.petitionsSub = this.store
      .pipe(
        select(getUser),
        switchMap((user: any) => {
          if (user) {
            return this.petitionsService.get();
          } else {
            return empty();
          }
        }),
        take(1)
      )
      .subscribe(petitions => {
        if (petitions.length === 0) {
          this.petitionsService.addPetitions(this.petitions);
        }
      });
  }
}
