import { Action } from '@ngrx/store';
import { Petition } from '../models/petition.model';

export enum PetitionsActionTypes {
  PETITIONS_QUERY = '[Petitions] Petitions query',
  PETITIONSNEWALL_QUERY = '[Petitions] Petitions New All query',
  PETITIONSNEWALLBYUSER_QUERY = '[Petitions] Petitions New By User query',
  PETITIONS_LOADED = '[Petitions] Petitions loaded',

  PETITION_ADDED = '[Petitions] Petition added',
  PETITIONSNEW_ADDED = '[Petitions] Petition New added',

  PETITION_EDITED = '[Petitions] Petition edited',
  PETITIONSNEW_EDITED = '[Petitions] Petition New edited',
  PETITION_DELETED = '[Petitions] Petition deleted',
  PETITIONNEW_DELETED = '[Petitions] Petition New deleted',

  PETITIONS_ERROR = '[Petitions] Petitions error',
}

export class PetitionsQuery implements Action {
  readonly type = PetitionsActionTypes.PETITIONS_QUERY;
}

export class PetitionsNewAllQuery implements Action {
  readonly type = PetitionsActionTypes.PETITIONSNEWALL_QUERY;
}

export class PetitionsNewAllByUserQuery implements Action {
  readonly type = PetitionsActionTypes.PETITIONSNEWALLBYUSER_QUERY;
}

export class PetitionsLoaded implements Action {
  readonly type = PetitionsActionTypes.PETITIONS_LOADED;

  constructor(public payload: { petitions: Petition[] }) {}
}

export class PetitionAdded implements Action {
  readonly type = PetitionsActionTypes.PETITION_ADDED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionsNewAdded implements Action {
  readonly type = PetitionsActionTypes.PETITIONSNEW_ADDED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionEdited implements Action {
  readonly type = PetitionsActionTypes.PETITION_EDITED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionsNewEdited implements Action {
  readonly type = PetitionsActionTypes.PETITIONSNEW_EDITED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionDeleted implements Action {
  readonly type = PetitionsActionTypes.PETITION_DELETED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionsNewDeleted implements Action {
  readonly type = PetitionsActionTypes.PETITIONNEW_DELETED;

  constructor(public payload: { petition: Petition }) {}
}

export class PetitionsError implements Action {
  readonly type = PetitionsActionTypes.PETITIONS_ERROR;

  constructor(public payload: { error: any }) {}
}

export type PetitionsActions =
  | PetitionsQuery
  | PetitionsLoaded
  | PetitionAdded
  | PetitionEdited
  | PetitionDeleted
  | PetitionsError;
