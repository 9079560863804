import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import {
  ButtonsModule,
  InputsModule,
  CardsModule,
  InputUtilitiesModule,
  IconsModule,
  ChartsModule,
} from 'ng-uikit-pro-standard';
import { CustomersModalComponent } from './components/customers-modal/customers-modal.component';
import { ProjectModalComponent } from './components/project-modal/project-modal.component';
import { FormsModule } from '@angular/forms';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile/customer-profile.component';
import { PetitionModalComponent } from './components/petition-modal/petition-modal.component';
import { PetitionsListComponent } from './components/petitions-list/petitions-list.component';
import { PetitionComponent } from './components/petition/petition.component';
import { PetitionsChartComponent } from './components/petitions-chart/petitions-chart.component';

@NgModule({
  declarations: [
    ConfirmModalComponent,
    CustomersModalComponent,
    ProjectModalComponent,
    ProjectsListComponent,
    ProjectComponent,
    PetitionModalComponent,
    PetitionsListComponent,
    PetitionComponent,
    CustomersListComponent,
    CustomerProfileComponent,
    PetitionsChartComponent,
  ],
  imports: [
    CommonModule,
    InputsModule,
    InputUtilitiesModule,
    IconsModule,
    FormsModule,
    ButtonsModule,
    CardsModule,
    ChartsModule,
  ],
  exports: [
    ProjectsListComponent,
    ProjectComponent,
    CustomersListComponent,
    PetitionsListComponent,
    PetitionsChartComponent,
  ],
  providers: [],
  entryComponents: [
    ConfirmModalComponent,
    CustomersModalComponent,
    ProjectModalComponent,
    PetitionModalComponent,
  ],
})
export class SharedModule {}
