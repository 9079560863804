import { Petition } from '../models/petition.model';

export interface PetitionsState {
  petitions: Petition[] | null;
  loading: boolean;
  error: any;
}

export const petitionsInitialState: PetitionsState = {
  petitions: null,
  loading: false,
  error: null,
};
