import { Injectable } from '@angular/core';
import { Petition } from '../models/petition.model';
import { environment } from '../../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class PetitionsService {
  url = environment.firebase.databaseURL;

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth
  ) { }

  get userId() {
    if (this.afAuth.auth.currentUser) {
      return this.afAuth.auth.currentUser.uid;
    }
  }

  add(petition: Petition) {
    const petitions = this.db.list('petitions');
    return petitions.push({
      ...petition,
      userId: this.userId,
    });
  }

  addPetitionNew(petition: Petition) {
    const petitions = this.db.list('petitionsnew');
    return petitions.push({
      ...petition,
      uid: this.userId,
    });
  }

  addPetitions(petitions: Petition[]) {
    petitions.forEach((petition: Petition) => {
      this.db.list('petitions').push({
        ...petition,
        userId: this.userId,
      });
    });
  }

  addPetitionsNew(petitions: Petition[]) {
    petitions.forEach((petition: Petition) => {
      this.db.list('petitionsnew').push({
        ...petition,
        uid: this.userId,
      });
    });
  }

  get() {
    return this.db.list(`petitions`).snapshotChanges();
  }

  getAllPetitions() {
    return this.db.list('petitionsnew').snapshotChanges();
  }

  getPetitionsByUser(userId: string) {
    return this.db.list('petitionsnew', ref => {
      return ref.orderByChild('uid').equalTo(userId)
    }).snapshotChanges();
  }

  update(petition: Petition) {
    return of(
      this.db.object(`petitions/${petition.key}`).update({
        serialNumber: petition.serialNumber,
        returnedDate: petition.returnedDate,
        notarizedAt: petition.notarizedAt,
        inRecovery: petition.inRecovery,
        county: petition.county,
        checkedOutAt: petition.checkedOutAt,
        campaignId: petition.campaignId,
        volunteerId: petition.volunteerId,
      })
    );
  }

  updatePetitionsNew(petition: Petition, userId: string) {
    return of(
      this.db.object(`petitionsnew/${petition.key}`).update({
        serialNumber: petition.serialNumber,
        returnedDate: petition.returnedDate,
        notarizedAt: petition.notarizedAt,
        inRecovery: petition.inRecovery,
        county: petition.county,
        checkedOutAt: petition.checkedOutAt,
        campaignId: petition.campaignId,
        volunteerId: petition.volunteerId,
        uid: userId
      })
    );
  }

  delete(petition: Petition) {
    return this.db.object(`petitions/${petition.key}`).remove();
  }

  deletePetitionsNew(petition: Petition) {
    return this.db.object(`petitionsnew/${petition.key}`).remove();
  }
}
