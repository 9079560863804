import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputsModule, ButtonsModule, CardsModule, IconsModule, WavesModule, InputUtilitiesModule } from 'ng-uikit-pro-standard';
import { RouterModule } from '@angular/router';
import { ConfirmresetmodalComponent } from './components/confirmresetmodal/confirmresetmodal.component';

@NgModule({
  declarations: [ForgotpasswordComponent, ChangepasswordComponent, ConfirmresetmodalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputsModule,
    InputUtilitiesModule,
    ButtonsModule,
    CardsModule,
    WavesModule,
    RouterModule,
    IconsModule
  ],
  entryComponents: [ForgotpasswordComponent, ChangepasswordComponent, ConfirmresetmodalComponent]
})
export class ManagepasswordsModule { }
