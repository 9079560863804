import { createSelector, createFeatureSelector } from '@ngrx/store';
// import { AppState } from '../../reducers/index';
import { PetitionsState } from './petitions.state';

export const getPetitionsState = createFeatureSelector<PetitionsState>(
  'petitions'
);

export const getPetitions = createSelector(
  getPetitionsState,
  petitions => petitions.petitions
);

export const getAllLoaded = createSelector(
  getPetitionsState,
  petitions => petitions.loading
);

export const getError = createSelector(
  getPetitionsState,
  petitions => petitions.error
);
