import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  constructor(public modalRef: MDBModalRef, private toastService: ToastService,
    private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  get email() { return this.forgotPasswordForm.get('email'); }

  onResetEmail(value: any) {
    if (this.forgotPasswordForm.valid) {
      let email = value.email;
      if (!email) {
        alert('Type in your email first');
      }
      this.afAuth.auth.sendPasswordResetEmail(email)
        .then(() => {
          this.modalRef.hide();
          this.toastService.success('A password reset link has been sent to your email address');
        },
          (rejectionReason: any) => alert(rejectionReason))
        .catch((_e: any) => this.toastService.success('An error occurred while attempting to reset your password'));
    }
  }
}
