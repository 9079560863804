import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MDBModalService, MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { ChangepasswordComponent } from 'src/app/managepasswords/components/changepassword/changepassword.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { getIsAdmin } from 'src/app/auth/store/auth.selectors';
import { ConfirmresetmodalComponent } from 'src/app/managepasswords/components/confirmresetmodal/confirmresetmodal.component';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent implements OnInit {
  @Input() user: any;
  @Output() userSelected = new EventEmitter<any>();
  @Output() addAdmin = new EventEmitter<any>();
  @Output() removeAdmin = new EventEmitter<any>();

  isAdmin: Observable<boolean>;

  modalRef: MDBModalRef;

  modalConfig = {
    class: 'modal-dialog-centered'
  };

  constructor(private store: Store<AppState>, private modalService: MDBModalService,
    private toastService: ToastService, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.isAdmin = this.store.select(getIsAdmin);
  }

  selectUser() {
    this.userSelected.emit(this.user);
  }

  addAdminPrivileges() {
    this.addAdmin.emit(this.user);
  }

  removeAdminPrivileges() {
    this.removeAdmin.emit(this.user);
  }

  //Forgot Password
  openChangePasswordModal() {
    this.modalRef = this.modalService.show(ChangepasswordComponent, this.modalConfig);
  }

  openChangePasswordConfirmModal() {
    this.modalRef = this.modalService.show(
      ConfirmresetmodalComponent,
      this.modalConfig
    );

    this.modalRef.content.resetconfirmation
      .pipe(take(1))
      .subscribe((confirmation: boolean) => {
        if (confirmation) {
          let userEmail = this.user.email;
          if (userEmail) {
            this.afAuth.auth.sendPasswordResetEmail(userEmail)
              .then(() => {
                this.modalRef.hide();
                this.toastService.success('A password reset link has been sent to the selected user email address');
              },
                (rejectionReason: any) => alert(rejectionReason))
              .catch((_e: any) => this.toastService.success('An error occurred while attempting to reset the password'));
          }
          else {
            this.toastService.success('An error occurred while attempting to reset the password');
          }
        }
      });
  }
}
