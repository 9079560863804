import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, ToastService } from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';

// import * as admin from 'firebase-admin'
// import * as serviceAccount from 'src/app/keys/ServiceAccountKey.json'

// const params = {
//   type: serviceAccount.type,
//   projectId: serviceAccount.project_id,
//   privateKeyId: serviceAccount.private_key_id,
//   privateKey: serviceAccount.private_key,
//   clientEmail: serviceAccount.client_email,
//   clientId: serviceAccount.client_id,
//   authUri: serviceAccount.auth_uri,
//   tokenUri: serviceAccount.token_uri,
//   authProviderX509CertUrl: serviceAccount.auth_provider_x509_cert_url,
//   clientC509CertUrl: serviceAccount.client_x509_cert_url
// }
   
// admin.initializeApp({
//   credential: admin.credential.cert(params),
// })

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangepasswordComponent;
  changePasswordForm: FormGroup;
  result$: User;
  constructor(public modalRef: MDBModalRef, private afAuth: AngularFireAuth, private toastrService: ToastService) {
  }

  ngOnInit() {
    this.changePasswordForm = new FormGroup({
      newpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),
      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ]))
    }, {
      validators: this.passwordCheck
    });
  }

  passwordCheck(formGroup: FormGroup) {
    let newpassword = formGroup.get('newpassword');
    let confirmpassword = formGroup.get('confirmpassword');
    if (newpassword && confirmpassword) {
      return newpassword.value === confirmpassword.value ? null : { passwordNotMatch: true };
    }
    else {
      return null;
    }
  }

  get newpassword() { return this.changePasswordForm.get('newpassword'); }
  get confirmpassword() { return this.changePasswordForm.get('confirmpassword'); }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  confirmPasswordReset(value: any) {
    if (this.changePasswordForm.valid) {
      var user = this.afAuth.auth.currentUser;
      var newPassword = value.newpassword;
      if (user) {
        user.updatePassword(newPassword).then(() => {
          this.modalRef.hide();
          this.toastrService.success('Password updated successfully');
        }).catch((_error) => {
          // An error happened.
          this.modalRef.hide();
          this.toastrService.success(_error);
        });
      }
    }
  }

  // changeAnonymousUserPasswod(value: any) {
  //   if (this.changePasswordForm.valid) {
  //     var newPassword = value.newpassword;
  //     var uid = this.result$.uid;
  //     if (uid && newPassword) {
  //       admin.auth().updateUser(uid, {
  //         password: newPassword
  //       })
  //         .then(function (userRecord) {
  //           // See the UserRecord reference doc for the contents of userRecord.
  //           console.log('User', userRecord.toJSON());
  //           this.toastrService.success('Successfully updated user password', userRecord.toJSON());
  //         })
  //         .catch(function (error) {
  //           this.toastrService.error('Error updating user:', error);
  //         });
  //     } else {
  //       this.toastrService.error('Error updating user');
  //     }
  //   }
  // }
}
